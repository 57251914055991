<template>
  <div :class="$style['last-order-btn']">
    <ActionCard
      v-if="store.lastOrders.length"
      :img="store.lastOrders[0]?.mostExpensiveProductImage || ''"
      :action="t('topup.my_orders')"
      :with-arrow="true"
      :class="$style['searcher-action-card']"
      size="xs"
      @click="showPopup"
    />
  </div>
  <Transition name="popup">
    <PopupContainer
      v-if="store.showLastOrderPopup && !isLoaded"
      :class="$style['popup-container']"
      @hide="hidePopup"
    >
      <LastOrderPopup @hide="hidePopup" />
    </PopupContainer>
  </Transition>
</template>

<script setup lang="ts">
import { useUserStore } from "~/stores/user.ts";
import ActionCard from "~/components/Shared/ActionCard/index.vue";
import PopupContainer from "~/components/Shared/Popup/PopupContainer.vue";
import LastOrderPopup from "~/components/LastOrders/LastOrderPopup.vue";

const { t } = useI18n();
const store = useUserStore();
const isLoaded = ref(false);
const init = async () => {
  isLoaded.value = true;
  if (store.userIsAuthorized) {
    await store.getUserOrders();
  }
  isLoaded.value = false;
};

onMounted(async () => {
  await init();
});

const showPopup = () => {
  store.showLastOrderPopup = true;
  document.documentElement.style.overflow = "hidden";
};

const hidePopup = () => {
  store.showLastOrderPopup = false;
  document.documentElement.style.overflow = "";
};

watch(
  () => store.userIsAuthorized,
  async (val) => {
    if (val) {
      await init();
    } else {
      store.lastOrders = [];
    }
  },
);
</script>
<style lang="scss" module>
.last-order-btn {
  & .searcher-action-card {
    position: fixed;
    left: 24px;
    bottom: 24px;
    width: 400px;
    height: 84px;
    z-index: 21;
    gap: var(--spacing-md);
    background: var(--general-underlay-4-lvl) !important;
    border-radius: var(--radius-6xl);
    padding: var(--spacing-2xl, 20px) var(--spacing-4xl, 24px);
    border: 1px solid var(--general-transparent-light-6);

    h1 {
      font-size: 18px;
      line-height: 100%;
      letter-spacing: -0.09px;

      @media screen and (max-width: 600px) {
        font-size: 16px;
        letter-spacing: -0.08px;
      }
    }
    @media screen and (max-width: 600px) {
      left: 12px;
      bottom: 12px;
      width: 205px;
      gap: var(--spacing-sm);
      max-height: 56px;
      padding: 0 var(--spacing-2lg) 0 var(--spacing-md);
      align-items: center;
    }

    img {
      width: 44px;
      height: 44px;
      border-radius: 100px;

      @media screen and (max-width: 600px) {
        width: 32px;
        height: 32px;
      }
    }

    svg {
      height: 16px;
      opacity: 1;
    }
  }

  .popup-container {
    @media screen and (max-width: 600px) {
      & > div {
        padding: 0;
      }
    }
  }
}
</style>
