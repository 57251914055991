<template>
  <form @submit.prevent="submit">
    <SharedInputField
      :error="errors.email"
      :class="$style.form_item"
    >
      <SharedInput
        v-model="credentials.email"
        :class="$style.form_item"
        :placeholder="$t('auth.email')"
        :has-error="Boolean(errors.email)"
      />
    </SharedInputField>
    <SharedInputField
      :error="errors.password"
      :class="$style.form_item"
    >
      <SharedInput
        v-model="credentials.password"
        type="password"
        :class="$style.form_item"
        :placeholder="$t('auth.password')"
        :has-error="Boolean(errors.password)"
      />
    </SharedInputField>
    <div
      v-if="errors.detail"
      :class="$style.error"
    >
      <SharedStatus status="failed">
        {{ errors.detail }}
      </SharedStatus>
    </div>
    <SharedButtonPrimaryButton :class="$style.form_item">
      {{ $t("auth.login") }}
    </SharedButtonPrimaryButton>
  </form>
</template>

<script lang="ts" setup>
import type { TokenObtainRequest } from "~/api_gen";
import { setTokens } from "~/utils/tokens/setTokens";

const userStore = useUserStore();
const credentials = reactive<TokenObtainRequest>({
  email: "",
  password: "",
});
const errors = reactive<TokenObtainRequest & { detail: string }>({
  email: "",
  password: "",
  detail: "",
});

const submit = async () => {
  try {
    const tokenPair = await userStore.loginUsingCredentials(credentials);
    setTokens(tokenPair);
    await nextTick();
    await userStore.getUser();
    userStore.hideAuthPopup();
  } catch (e) {
    console.error(e);
  }
};
</script>

<style module>
.form_item {
  width: 100%;
}
.error {
  margin: 0px 0 10px;
}
</style>
