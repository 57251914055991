<template>
  <div :class="$style['page-header']">
    <div :class="$style['inner-container']">
      <div
        :class="[
          $style['inner-block'],
          stretchInnerBlock ? $style.stretched : '',
        ]"
      >
        <Logo :class="{ [$style.hidden]: hideLogo }" />
        <Searcher
          @active="handleSearchActiveness"
          @inactive="handleSearchInactiveness"
        />
      </div>
      <div>
        <SharedButtonPrimaryButton
          v-if="!userStore.userIsAuthorized"
          :class="{ [$style.hidden]: hideUserBlock }"
          style="width: 110px"
          @click="userStore.showAuthPopup"
        >
          {{ t("auth.login") }}
        </SharedButtonPrimaryButton>
        <div
          v-else
          :class="$style['user-controls']"
        >
          <Balance
            v-if="showBalance"
            v-tooltip="$t('profile.balance_wip')"
            :balance="Number(userStore?.userData?.balance)"
          ></Balance>
          <UserMenu :class="{ [$style.hidden]: hideUserBlock }" />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import Logo from "./PageHeader/Logo.vue";
import Searcher from "./PageHeader/Searcher.vue";
import UserMenu from "./PageHeader/UserMenu.vue";
import Balance from "~/components/PageHeader/Balance.vue";

const { t } = useI18n();
const userStore = useUserStore();
const hideLogo = ref(false);
const hideUserBlock = ref(false);
const stretchInnerBlock = ref(false);
const showBalance = computed(
  () => userStore.userData && Number(userStore.userData.balance) > 0,
);

function handleSearchActiveness() {
  hideLogo.value = true;
  hideUserBlock.value = true;
  stretchInnerBlock.value = true;
}

function handleSearchInactiveness() {
  hideLogo.value = false;
  hideUserBlock.value = false;
  stretchInnerBlock.value = false;
}
</script>

<style lang="scss" module>
.page-header {
  position: sticky;
  top: 0;
  z-index: 100;
  height: 90px;
  max-width: 1440px;
  margin: auto;
  padding: 0 var(--spacing-6xl);
  border-radius: 0 0 var(--radius-5xl) var(--radius-5xl);
  background-color: var(--general-underlay-1-lvl);
  @media screen and (max-width: 450px) {
    height: 70px;
    padding: 0 var(--spacing-2lg);
  }
}
.inner-container,
.inner-block {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.inner-container {
  height: 100%;
  width: 100%;
  max-width: $content-max-width;
  margin: auto;
}
.stretched {
  @media screen and (max-width: $tablet) {
    width: 100%;
  }
}
.hidden {
  @media screen and (max-width: $tablet) {
    display: none;
  }
}

.user-controls {
  display: flex;
  align-items: center;
  gap: var(--spacing-xs);
}
</style>
