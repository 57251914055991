<template>
  <div :class="$style.container">
    <p :class="$style.title">{{ $t("auth.login_using") }}:</p>
    <div :class="$style.socials">
      <SharedButtonPopupButton
        size="s"
        data-test="social-google-btn"
        @click="handle('google')"
      >
        <IconGoogle />
      </SharedButtonPopupButton>
      <SharedButtonPopupButton
        size="s"
        data-test="social-vk-btn"
        @click="handle('vk')"
      >
        <IconVk />
      </SharedButtonPopupButton>
      <SharedButtonPopupButton
        size="s"
        data-test="social-yandex-btn"
        @click="handle('yandex')"
      >
        <IconYandex />
      </SharedButtonPopupButton>
      <SharedButtonPopupButton
        size="s"
        data-test="social-telegram-btn"
        @click="handle('telegram')"
      >
        <IconTg />
      </SharedButtonPopupButton>
      <SharedButtonPopupButton
        size="s"
        data-test="social-steam-btn"
        @click="handle('steam')"
      >
        <IconSteam />
      </SharedButtonPopupButton>
    </div>
  </div>
</template>

<script lang="ts" setup>
import * as Sentry from "@sentry/vue";
import type { SocialNames } from "~/types/auth";

const userStore = useUserStore();
const blocked = ref(false);
const social = ref<SocialNames>();
const route = useRoute();

async function handle(name: SocialNames) {
  if (blocked.value) return;
  try {
    blocked.value = true;
    social.value = name;
    const redirectUri = userStore.getSocialRedirectUri(name);
    const { url } = await userStore.getSocialAuthUrl(name, redirectUri);
    const state: string = `&state=${encodeURIComponent(
      JSON.stringify({ path: route.path }),
    )}`;
    reloadNuxtApp({
      path: url + state,
    });
  } catch (e) {
    Sentry.captureException(e);
    console.log(e);
  } finally {
    blocked.value = false;
  }
}
</script>

<style lang="scss" module>
.container {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-2lg);
}
.title {
  color: var(--general-transparent-light-100);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%; /* 18.2px */
  letter-spacing: -0.014px;
}
.socials {
  display: flex;
  flex-direction: row;
  gap: var(--spacing-xs);
  :global(svg) {
    width: 14px;
  }
}
</style>
