<template>
  <SharedInputField
    v-if="!promoCodeInfoRequired"
    :error="error"
  >
    <SharedInput
      v-model="promoCode"
      ref="inputRef"
      size="l"
      placeholder="Вставьте промокод"
      @onFocus="handleInputFocus"
      @onBlur="handleInputBlur"
    >
      <template #leftIcon>
        <IconPromoCode width="18" />
      </template>
      <template #rightIcon>
        <div :class="$style['spinner-wrapper']">
          <LoadingSpinner
            v-if="promoCodeStore.isPromoCodeChecking"
            width="18"
          />
        </div>
      </template>
    </SharedInput>
  </SharedInputField>
  <div
    v-else
    ref="promoInfoRef"
    :class="$style['promo-code-data']"
    @click="handlePromoCodeDataClick"
  >
    <IconPromoCode width="18" />
    {{ description }}
    <IconCheck width="18" />
  </div>
</template>

<script setup lang="ts">
import LoadingSpinner from "~/components/Shared/Spinners/LoadingSpinner.vue";
import { usePromoCodesStore } from "../../stores/promoCodes";
import { watch, nextTick } from "vue";
import type { PromoCode } from "~/api_gen";

const promoCode = defineModel<string>();
const props = defineProps<{
  error?: string;
  currentPromoCodeData?: PromoCode;
  inactive: boolean;
}>();
const { error, inactive, currentPromoCodeData } = toRefs(props);
const promoCodeStore = usePromoCodesStore();
const inputFocused = ref<boolean>();
const inputRef = ref<HTMLElement>();
const promoInfoRef = ref<HTMLElement>();

const promoCodeInfoRequired = computed(
  () =>
    inactive.value ||
    (!error.value && !inputFocused.value && currentPromoCodeData.value),
);

const handleInputFocus = () => {
  inputFocused.value = true;
};
const handleInputBlur = () => {
  inputFocused.value = false;
};
const handlePromoCodeDataClick = () => {
  inputFocused.value = true;
  nextTick(() => {
    if (inputRef.value) {
      inputRef.value.focus();
    }
  });
};
watch(promoCode, () => {
  if (promoCode.value) {
    promoCodeStore.checkPromoCode(promoCode.value);
  }
});
watch(currentPromoCodeData, () => {
  if (currentPromoCodeData.value) {
    inputFocused.value = false;
  }
});
const { t } = useI18n();
const description = computed(() =>
  t("promo_code.info", {
    code: currentPromoCodeData.value?.code,
    percent: toLocalNumber(Number(currentPromoCodeData.value?.percent)),
  }),
);
</script>

<style module>
.promo-code-data {
  display: flex;
  height: 54px;
  padding: var(--spacing-xs) var(--spacing-2xl);
  align-items: center;
  gap: var(--spacing-md);
  border-radius: var(--radius-max);
  background: var(--input-default);
  color: var(--optional-mint);
  justify-content: space-between;
  cursor: pointer;
}
.spinner-wrapper {
  position: relative;
  width: 18px;
}
</style>
