import type { PromoCode } from "~/api_gen";

export const usePromoCodesStore = defineStore("promoCodesStore", () => {
  const isPromoCodePopupRequired = ref(false);
  const isPromoCodeChecking = ref(false);
  const isPromoCodeActivating = ref(false);

  const currentPromoCodeData = ref<PromoCode>();
  const userStore = useUserStore();

  const checkPromoCode = async (code: string) => {
    try {
      currentPromoCodeData.value = undefined;
      isPromoCodeChecking.value = true;
      const data = await useApi().promoCodes.codesShow({
        activatePromoCodeRequest: { code },
      });
      if (data) {
        currentPromoCodeData.value = data;
      } else {
        currentPromoCodeData.value = undefined;
      }
    } finally {
      isPromoCodeChecking.value = false;
    }
  };

  const activatePromoCode = async (code: string) => {
    try {
      isPromoCodeActivating.value = true;
      const data = await useApi().promoCodes.codesActivate({
        activatePromoCodeRequest: { code },
      });
      userStore.getUser();
      return data;
    } finally {
      isPromoCodeActivating.value = false;
    }
  };

  const deactivatePromoCode = async () => {
    try {
      isPromoCodeActivating.value = true;
      const data = await useApi().promoCodes.codesDeactivate();
      userStore.getUser();
      return data;
    } finally {
      isPromoCodeActivating.value = false;
    }
  };

  return {
    isPromoCodePopupRequired,
    isPromoCodeChecking,
    currentPromoCodeData,
    activatePromoCode,
    deactivatePromoCode,
    checkPromoCode,
  };
});
